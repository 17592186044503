<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">{{ title }}</h4>

          <div class="page-title-right">
            <!--  <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
            <div>
              <b-button id="show-btn" variant="primary" :to="{ name: 'currencies-index' }">Lista de Monedas</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 justify-content-center">
      <div class="col-xl-8">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <!-- <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Remove</a>
              </b-dropdown> -->
            </div>
            <hr class="my-4" />
            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <p class="mb-1">{{ $t("currencies.name") }}:</p>
                  <h5 class="font-size-16">{{ item.name }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">{{ $t("currencies.abbreviation") }}:</p>
                  <h5 class="font-size-16">{{ item.short }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t("currencies.symbol") }}:</p>
                  <h5 class="font-size-16">{{ item.symbol }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t("currencies.form.exchangeValue") }}:</p>
                  <h5 class="font-size-16">{{ item.usdExchange }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('profile.creationDate') }} :</p>
                  <h5 class="font-size-16">{{ item.createdAt }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

/**
 * Profile component
 */
export default {
  components: { Layout },
  page: {
    title: "Moneda",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Moneda",
      item: []
    };
  },
  created() {
    if (this.$router.currentRoute.params.id) {
      this.$http.get(`currencies/${this.$router.currentRoute.params.id}`)
        .then(response => { this.item = response.data.currency })
        .catch(error => {
          console.log(error)
        })
    }
    else {
      this.$router.push({ path: '/currencies' })
    }

  }

};
</script>
